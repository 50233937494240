import React from 'react';

const Index = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clipRule="evenodd" d="M20 10C20 15.5194 15.5284 19.9944 10.0103 20L9.98795 20C4.47064 19.9935 0 15.5188 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM3.07026 6C4.01641 4.36439 5.525 3.09485 7.33157 2.45584C7.2771 2.53363 7.22252 2.61345 7.16795 2.6953C6.58709 3.56659 6.008 4.6689 5.59463 6H3.07026ZM2.25203 8C2.08751 8.63924 2 9.3094 2 10C2 10.6906 2.08751 11.3608 2.25203 12H5.1427C5.05127 11.3711 5 10.7044 5 10C5 9.29559 5.05127 8.62888 5.1427 8H2.25203ZM7.16765 8C7.06118 8.61988 7 9.28646 7 10C7 10.7135 7.06118 11.3801 7.16765 12H12.8324C12.9388 11.3801 13 10.7135 13 10C13 9.28646 12.9388 8.61988 12.8324 8H7.16765ZM14.8573 8C14.9487 8.62888 15 9.29559 15 10C15 10.7044 14.9487 11.3711 14.8573 12H17.748C17.9125 11.3608 18 10.6906 18 10C18 9.3094 17.9125 8.63924 17.748 8H14.8573ZM16.9297 6H14.4054C13.992 4.6689 13.4129 3.56659 12.8321 2.6953C12.7775 2.61345 12.7229 2.53363 12.6684 2.45584C14.475 3.09485 15.9836 4.36439 16.9297 6ZM12.2923 6H7.70774C8.03204 5.13517 8.43264 4.40381 8.83205 3.8047C9.25354 3.17246 9.67352 2.68818 10 2.35283C10.3265 2.68818 10.7465 3.17246 11.1679 3.8047C11.5674 4.40381 11.968 5.13517 12.2923 6ZM11.1679 16.1953C11.5674 15.5962 11.968 14.8648 12.2923 14H7.70774C8.03204 14.8648 8.43264 15.5962 8.83205 16.1953C9.25354 16.8275 9.67352 17.3118 10 17.6472C10.3265 17.3118 10.7465 16.8275 11.1679 16.1953ZM3.07026 14H5.59463C6.008 15.3311 6.58709 16.4334 7.16795 17.3047C7.22252 17.3865 7.2771 17.4664 7.33157 17.5442C5.525 16.9052 4.01641 15.6356 3.07026 14ZM12.8321 17.3047C12.7775 17.3865 12.7229 17.4664 12.6684 17.5442C14.475 16.9052 15.9836 15.6356 16.9297 14H14.4054C13.992 15.3311 13.4129 16.4334 12.8321 17.3047Z" fill="white"/>
      </svg>
    </>
  );
};

export default Index;
